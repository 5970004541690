body, html {
  margin: 0;
  padding: 0;
  background-color: #252525; /* Global background color */
  font-family: sans-serif;
}

.App {
  text-align: center;
  background-color: #252525; /* Set the background color for the App container */
  color: #170E0E;
  min-height: 100vh; /* Ensure the app covers the full viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #252525; /* Match the global background color */
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Keep the rest of your existing styles as they are */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
